<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="allCardList p-0">
        <iq-card style="">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i :class="`fa-solid fa-${orgAvatar[orgType]}`" aria-hidden="true" style="font-size: 21px; margin-right: 10px; color: var(--iq-primary);"></i>
              <span>{{orgTitle[orgType]}}</span>
              <span class="btn_in_header">
                <span class="pull-right" v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'">
                  <b-button variant="primary" @click="organisationAdd">Add (+)</b-button>
                </span>
              </span>
            </h4>
          </template>
        </iq-card>

        <b-col md="12" class="p-0">
            <iq-card style="width:100%;" >
              <div class="p-0 showFlexListDiv">
                <div class="user-tabing showFlexList">
                  <tab-nav :pills="true" id="pills-tab" class="nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0 w-100">
                    <div  class="flex-fill p-0" @click="switchTabs('allOrganisations')">
                      <tab-nav-items class="flex-fill p-0" :active="allOrganisationActive" id="pills-all-organisations-tab" href="#profile-all-organisations" ariaControls="pills-all-organisations" role="tab" :ariaSelected="allOrganisationActive" :title="`All ${orgTitle[orgType]}`" />
                    </div>
                    <div  class="flex-fill p-0" @click="switchTabs('followedOrganisations')" >
                      <tab-nav-items  class="flex-fill p-0" :active="followingOrganisationActive" id="pills-following-organisations-tab"  href="#profile-following-organisations"  ariaControls="pills-following-organisations" role="tab" :ariaSelected="followingOrganisationActive" :title="`Followed ${orgTitle[orgType]}`" />
                    </div>
                  </tab-nav>
                </div>
              </div>
            </iq-card>
        </b-col>

        <b-col sm="12" class="p-0">
          <div class="tab-content">
            <tab-content-item :active="true" id="profile-all-organisations" aria-labelled-by="pills-all-organisations-tab">
              <div class="rounded p-2 bg-white iq-card">
                <b-form-input v-model="vmOrgSearch" placeholder="Search" @input="startSearch(vmOrgSearch)">
                </b-form-input>
              </div>
              <div v-if="Object.values(organisationListObj).length >0">
                <b-row class="p-2">
                  <div v-for="(org,index) in organisationListObj" :key="index" class="mt-5 col-12 col-sm-6 col-md-4 col-lg-3">
                    <iq-card className="iq-card-block iq-card-stretch iq-card-height side_border">
                      <template v-slot:body>
                        <div class="iq-badges text-left" @click="goToDetails(org.org_id)" style="cursor: pointer;">
                          <div class="badges-icon">
                            <b-avatar style="border-radius: 100%!important;" class="avatar-80 rounded" v-if="org.org_profile_img">
                              <img :src="org.org_profile_img" class="avatar-80 rounded" alt="Organisation">
                            </b-avatar>
                            <b-avatar v-else class="avatar-80" :text="getFirstLetterOfAString(org.org_name)" >
                            </b-avatar>
                            <div class="status" v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'" @click="organisationPublishUnpublish($event, org)">
                              <b-badge v-if="org.deleted === 0" class="pointer float-right " variant="success mr-1 mb-1" size="sm">
                                Published
                              </b-badge>
                              <b-badge v-if="org.deleted === 2" class="pointer ml-1" variant="warning mr-1 mb-1" size="sm">
                                Unpublished
                              </b-badge>
                              <b-badge v-if="org.deleted === 1" class="pointer ml-1" variant="primary mr-1 mb-1" size="sm">
                                Deleted
                              </b-badge>
                            </div>
                          </div>
                          <h5 class="mb-2 title twoLineOnly" :title="org.org_name">
                            {{org.org_name}}
                          </h5>
                        </div>
                        <span class="mr-2 font-weight-normal" variant="primary">
                        {{org.org_city}}{{ " " }}{{org.org_country }}
                        </span>
                        <button style="all:unset;float: right; font-weight: 500; cursor: pointer;" @click="followOrganisation($event, org.org_id, organisationFollowObj[org.org_id])">
                          <span v-if="!organisationFollowObj[org.org_id]" class="text-primary">Follow</span>
                          <span v-else class="text-secondary">Following</span>
                        </button>
                      </template>
                    </iq-card>
                  </div>
                </b-row>
                <div class="d-flex justify-content-center flex-row flex-fill mb-4" v-if="showLoadMoreBtn">
                  <button class="btn btn-primary" @click="loadMore('allOrganisations')">
                    Load More
                  </button>
                </div>
              </div>
              <div v-else class="p-2 text-center flex flex-fill justify-content-center">
                No Data Found
              </div>
            </tab-content-item>
            <tab-content-item :active="false" id="profile-following-organisations" aria-labelled-by="pills-following-organisations-tab">
              <b-row class="p-2">
                <div v-for="(org,index) in organisationFollowObj" :key="index" class="mt-5 col-12 col-sm-6 col-md-4 col-lg-3">
                  <iq-card className="iq-card-block iq-card-stretch iq-card-height side_border">
                    <template v-slot:body>
                      <div class="iq-badges text-left" @click="goToDetails(org.org_id)" style="cursor: pointer;">
                        <div class="badges-icon">
                          <b-avatar style="border-radius: 100%!important;" class="avatar-80 rounded" v-if="org.org_profile_img == null" :text="getFirstLetterOfAString(org.org_name)">
                          </b-avatar>
                          <b-avatar style="border-radius: 100%!important;"  v-else class="avatar-80 rounded">
                            <img :src="org.org_profile_img" class="avatar-80" alt="Organisation">
                          </b-avatar>
                        </div>
                          <h5 class="mb-2 title twoLineOnly" :title="org.org_name">
                            {{org.org_name}}
                          </h5>
                        </div>
                        <div class="d-flex justify-content-between">
                          <span class="mr-2 font-weight-normal" variant="primary">
                            {{org.org_city}}{{ " " }}{{org.org_country}}
                          </span>
                          <button style="all:unset; font-weight: 500; cursor: pointer;" @click="followOrganisation($event, org.org_id, organisationFollowObj[org.org_id])">
                            <span class="text-primary">Unfollow</span>
                          </button>
                        </div>
                    </template>
                  </iq-card>
                </div>
              </b-row>
            </tab-content-item>
          </div>
        </b-col>

        <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
          <div v-html="toastMsg">
          </div>
        </b-toast>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scoped >
  .iq-card-stretch{
    border-radius: 20px;
  }
  .followButton{
    min-height: unset;
    max-height: 50px;
  }
  .badges-icon {
    position: relative;
  }
  .status{
    position: absolute;
    bottom: 0;
    right: 0;
  }
</style>
<script>
import { socialvue } from "../../../config/pluginInit"
import { Organisations } from "../../../FackApi/api/organisation.js"
import { Follows } from "../../../FackApi/api/follow.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import Utility from "../../../Utils/utility.js"

export default {
  name: "organisationsList",
  data () {
    return {
      soicalInfo: [
        {
          name: "My Organisations"
        },
        {
          name: "All Organisations"
        }
      ],
      orgTitle: {
        UNIV: "Universities",
        SCH: "Schools",
        SCHGRP: "School Groups",
        ORG: "Organisations",
        EDTECH: "Edtechs"
      },
      orgAvatar: {
        UNIV: "university",
        SCH: "school",
        ORG: "building",
        EDTECH: "edtech"
      },
      delObj: {},
      orgType: "",
      showModelOrganisationDelete: false,
      organisationListObj: {},
      allOrganisationActive: true,
      followingOrganisationActive: false,
      organisationImgObj: {},
      organisationFollowObj: {},
      allOrganisationCount: 0,
      currentPage: 1,
      totalPage: "",
      showLoadMoreBtn: false,
      vmOrgSearch: "",
      vmFollowData: Object.assign({}, this.initFollowData()),
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Organisation Follow Response",
      vmBannerImage: Utility.getBgImageForLetter(this, "CARR")
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    organisations () {
      return this.$store.getters["Organisation/selectedOrganisation"]
    },
    schools () {
      return this.$store.getters["Schools/selectedSchool"]
    },
    universities () {
      return this.$store.getters["Universities/selectedUniversity"]
    }
  },
  watch: {
    "$route.path": function (newPath, oldPath) {
      this.refreshPage = newPath
      this.organisationListObj = {}
      this.organisationFollowObj = {}
      this.currentPage = 1
      this.loadRequiredData()
    }
  },
  beforeMount () {
    this.orgType = this.$route.params.org_type

    // Auth Access for Listed Role
    if (!(this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118") &&
      this.orgType !== "UNIV"
    ) {
      this.$router.push("/organisations/UNIV")
    }
  },
  mounted () {
    this.loadRequiredData()
  },
  methods: {
    /**
     * initFollowData
     */
    initFollowData () {
      return {
        module_id: "",
        module_name: "",
        follow_status: 0
      }
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      socialvue.index()
      this.orgType = this.$route.params.org_type
      this.organisationsList()
      this.organisationFollowedList()
    },
    /**
       * goToDetails
       */
    goToDetails (orgId) {
      this.$router.push(`/organisations/${this.orgType}/${orgId}`)
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * getResizedText
     */
    getResizedText (text) {
      return text ? text.substr(0, 100) : ""
    },
    /**
     * getBgColorForLetter
     */
    getBgColorForLetter (name) {
      let firstLetter = this.getFirstLetterOfAString(name)
      return Utility.getBgColorForLetter(this, firstLetter)
    },
    /**
     * goToOrganisationView
     */
    goToOrganisationView (orgId) {
      this.$router.push("/organisation/" + orgId)
    },
    /*
   * organisationsList
   * */
    async organisationsList (searchString = null, loadViaLoadMore = false) {
      try {
        let filter = { type: [this.orgType], user_role: this.userData.user_role }
        if (searchString && searchString.length >= 3) {
          filter = { ...filter,
            where: {
              "OR":
                [
                  {
                    org_name: searchString
                  },
                  {
                    org_desc: searchString
                  },
                  {
                    org_city: searchString
                  },
                  {
                    org_country: searchString
                  },
                  {
                    org_founding_year: searchString
                  },
                  {
                    org_avg_tuition_min: searchString
                  },
                  {
                    org_avg_tuition_max: searchString
                  },
                  {
                    org_scholarship_min: searchString
                  },
                  {
                    org_scholarship_max: searchString
                  },
                  {
                    org_deadline: searchString
                  }
                ]
            }
          }
        }

        // get the data from localStore
        let storeData
        switch (this.orgType) {
          case "ORG":
            storeData = this.organisations
            break
          case "UNIV":
            storeData = this.universities
            break
          case "SCH":
            storeData = this.schools
            break
        }

        if (storeData && Object.keys(storeData).length > 0 && (!searchString || searchString == "") && !loadViaLoadMore) {
          // If the data is present in the store, no search is happening, and the data is not loaded via the Load More button, then set all the variables from the store itself
          if (storeData.currentPage) {
            this.currentPage = parseInt(storeData.currentPage)
          }

          if (storeData.totalPage) {
            this.totalPage = parseInt(storeData.totalPage)
          }

          if (this.currentPage && this.totalPage && this.currentPage >= this.totalPage) {
            this.showLoadMoreBtn = false
          }
          else {
            this.showLoadMoreBtn = true
          }

          if (this.currentPage === 1) {
            this.organisationListObj = { ...storeData.organisationListObj }
          }
          else {
            this.organisationListObj = { ...this.organisationListObj, ...storeData.organisationListObj }
          }
          this.allOrganisationCount = storeData.totalCount
        }

        // If a new Org is added by the admin then it does not show in the list.
        // @TODO: Get the count always & if there is mismatch between the LS Total Count and the DB Total count then clear the LS and fire the LIST API.
        {
          let organisationsListResp = await Organisations.organisationList(this, filter, this.currentPage)

          if (organisationsListResp && organisationsListResp.resp_status) {
            if (organisationsListResp.resp_data.currentPage) {
              this.currentPage = parseInt(organisationsListResp.resp_data.currentPage)
            }

            if (organisationsListResp.resp_data.totalPage) {
              this.totalPage = parseInt(organisationsListResp.resp_data.totalPage)
            }

            if (this.currentPage && this.totalPage && this.currentPage >= this.totalPage) {
              this.showLoadMoreBtn = false
            }
            else {
              this.showLoadMoreBtn = true
            }

            this.allOrganisationCount = organisationsListResp.resp_data.totalCount

            if (this.currentPage === 1) {
              // If the user is performing a search then include his search results only in the list
              this.organisationListObj = { ...organisationsListResp.resp_data.data }
            }
            else {
              this.organisationListObj = { ...this.organisationListObj, ...organisationsListResp.resp_data.data }
            }

            if (searchString === "" || !searchString) {
              let organisationItemListObj = {
                organisationListObj: this.organisationListObj,
                organisationListObj_allOrganisationCount: this.allOrganisationCount,
                currentPage: this.currentPage
              }

              switch (this.orgType) {
                case "ORG":
                  this.$store.commit("Organisations/addOrganisation", organisationItemListObj)
                  break
                case "UNIV":
                  this.$store.commit("Universities/addUniversity", organisationItemListObj)
                  break
                case "SCH":
                  this.$store.commit("Schools/addSchool", organisationItemListObj)
                  break
              }

              // If all data is loaded then dont show loadmore
            }
          }
          else if (organisationsListResp.resp_code == "ERR_DATA_NOT_FOUND") {
            switch (this.orgType) {
              case "ORG":
                if (this.organisations && Object.keys(this.organisations).length > 0) {
                  this.organisationListObj = this.organisations.organisationListObj
                }
                break
              case "UNIV":
                if (this.universities && Object.keys(this.universities).length > 0) {
                  this.organisationListObj = this.universities.organisationListObj
                }
                break
              case "SCH":
                if (this.schools && Object.keys(this.schools).length > 0) {
                  this.organisationListObj = this.schools.organisationListObj
                }
                break
            }
            this.showLoadMoreBtn = false
          }
          else {
            this.organisationListObj = {}
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationsList() and Exception:", err.message)
      }
    },
    /*
     * organisationFollowedList
     * */
    async organisationFollowedList () {
      const filter = {
        type: [this.orgType]
      }

      this.isLoading = true
      try {
        let organisationFollowedListResp = await Organisations.organisationFollowedList(this, filter, this.followOrgTabcurrentPage)
        if (organisationFollowedListResp && organisationFollowedListResp.resp_status) {
          this.organisationFollowObj = organisationFollowedListResp.resp_data.data
        }
        else {
          this.allOrgLoadedFlag = true
        }
      }
      catch (err) {
        this.allOrgLoadedFlag = false
        console.error("Exception occurred at organisationFollowedList() and Exception:", err.message)
      }
      finally {
        this.isLoading = false
      }
    },
    /**
       *  organisationFollow ()
       */
    async followOrganisation (event, moduleId, followStatus) {
      event.stopPropagation()
      // Here I am optimystically updating the UI for better user experience
      if (followStatus) {
        delete this.organisationFollowObj[moduleId]
      }
      else {
        this.organisationFollowObj[moduleId] = this.organisationListObj[moduleId]
      }
      try {
        this.vmFollowData.module_id = moduleId
        this.vmFollowData.module_name = this.orgType
        this.vmFollowData.follow_status = followStatus ? 0 : 1
        let followResp = await Follows.followAdd(this, this.vmFollowData)
        await ApiResponse.responseMessageDisplay(this, followResp)
        if (followResp && !followResp.resp_status) {
          // If for any reason the followAdd api fails I will undo the UI updates
          if (followStatus) {
            this.organisationFollowObj[moduleId] = this.organisationListObj[moduleId]
          }
          else {
            delete this.organisationFollowObj[moduleId]
          }
          return
        }
        this.vmFollowData = Object.assign({}, this.initFollowData())
      }
      catch (err) {
        console.error("Exception occured at followOrganisation() and Exception", err.message)
      }
    },
    /**
     * Publish/UnPublish Organisation
     */
    async organisationPublishUnpublish (event, org) {
      event.stopPropagation()
      let payload = {
        org_id: org.org_id,
        deleted: null
      }

      if (org.deleted === 0) {
        // org is published then unpublish
        payload.deleted = 2
      }
      else if (org.deleted === 2) {
        payload.deleted = 0
      }
      else {
        return
      }

      let orgResp = await Organisations.organisation_publish_unpublish(this, payload)
      if (orgResp.resp_status === true) {
        org.deleted = payload.deleted
      }
      else {
        ApiResponse.responseMessageDisplay(this, orgResp)
      }
    },
    /**
     * switchTabs
     */
    async switchTabs (currentTab) {
      try {
        if (this.allOrganisationActive && currentTab === "allOrganisations") {
          return
        }
        else if (this.followingOrganisationActive && currentTab === "followedOrganisations") {
          return
        }
        else {
          let currPage = 1
          this.currentPage = currPage
          this.totalPage = ""
          if (currentTab === "allOrganisations") {
            this.allOrganisationActive = true
            this.followingOrganisationActive = false
            await this.organisationsList()
          }
          else if (currentTab === "followedOrganisations") {
            this.allOrganisationActive = false
            this.followingOrganisationActive = true
            await this.organisationFollowedList()
          }
        }
      }
      catch (err) {
        console.error("Exception occured at switchTabs() and Exception", err.message)
      }
    },
    /**
     * organisationAdd
     */
    organisationAdd () {
      try {
        this.$router.push("/organisation_add")
      }
      catch (err) {
        console.error("Exception occurred at organisationAdd() and Exception:", err.message)
      }
    },
    /**
     * startSearch
     */
    startSearch (vmOrgSearch) {
      if (vmOrgSearch && vmOrgSearch.length <= 3) {
        // Donot fire api if search string length is less than 4
        return
      }
      console.log("Search string1 ", vmOrgSearch)
      this.currentPage = 1
      this.organisationsList(vmOrgSearch)
    },
    /**
     * loadMore()
     */
    loadMore (currentTab) {
      try {
        let currPage = this.currentPage
        this.currentPage = parseInt(currPage) + 1
        if (this.vmOrgSearch && currentTab === "allOrganisations") {
          this.organisationsList(this.vmOrgSearch, true)
        }
        else if (currentTab === "allOrganisations") {
          this.organisationsList(null, true)
          // Passing searchString = null, loadViaLoadMore = true so as not to use the storeData and make an api call
        }
      }
      catch (err) {
        console.error("Exception occured at loadMore() and Exception", err.message)
      }
    }
  }
}
</script>
